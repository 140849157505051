import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
  *{
    box-sizing:border-box;
    outline:none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    color:#2C2C2C;
  }
  a{
    text-decoration:none;
  }
  html{
    font-size:20px;
    scroll-behavior:smooth
  }
  body{
    -webkit-overflow-scrolling: touch;
    overflow:scroll;
    margin:0 auto;
    min-height:100vh;
    position: relative;
    background:#fff;
    font-weight:400;
    -webkit-overflow-scrolling: touch;
    overflow:scroll;
    margin:0 auto;
    min-height:100vh;
    position: relative;
    background:#fff;
    font-weight:400;
    font-family:"PingFang SC", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Arial,
 "Hiragino Sans GB", "Source Han Sans", "Noto Sans CJK Sc", "Microsoft YaHei", "Microsoft Jhenghei", sans-serif;


  }
  
  
  @media screen and (max-width: 1000px){
      html {
          font-size: 14px;
      }
  }
   @media screen and (min-width: 1000px){
      html {
          font-size: 16px;
      }
  }
  @media screen and (min-width: 1200px){
      html {
          font-size: 18px;
      }
  } 
 
  @media screen and (min-width: 1300px){
      html {
          font-size: 20px;
      }
  } 
`;

export default GlobalStyle;
